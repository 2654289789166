<template>
  <div style="width: 100%">
    <div v-if="media.type === 'image'" key="image" >
      <img class="media-image" :src="src" :aria-label="media.alt"/>
    </div>
    <div v-else-if="media.type === 'video'">
    </div>
    <div v-else-if="media.type === 'imagegif'" >
      <v-img v-if="media.type === 'imagegif'" :src="src" :aria-label="media.alt"> </v-img>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    playlist: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    if (this.playlist == null || this.playlist.length <= 0) {
      console.log("No media in playlist");
      return;
    }
    this.media = this.playlist[0];
    this.src = this.media.src;
    this.startTimer();
  },
  methods: {
    startTimer() {
      let duration = this.media.duration;

      this.myTimer = setInterval(() => {
        if (duration <= 1) {
          if (this.currentIndex === this.playlist.length) {
            clearInterval(this.myTimer);
          } else {
            this.media = this.playlist[this.currentIndex];
            this.src = this.media.src;
            duration = this.media.duration;
            this.currentIndex++;
          }
        } else {
          duration--;
        }
      }, 1000);
    },

    togglePlaylist() {
      this.currentIndex++;
      this.media = this.playlist[this.currentIndex];
    },
  },
  data() {
    return {
      media: {
        type: "image",
         alt: "",
         src: "",
         duration: 0,
       },
      mediaTimer: null,
      currentIndex: 0,
      src:''
    };
  },
};
</script>

<style>
.media-image {
  align-content: center;
  vertical-align: middle;
  max-height: 90vh;  
  max-width: 100%;
  aspect-ratio: auto;
  height: max-content;
}
.media-gif {
  height: auto;
  align-content: center;
  vertical-align: middle;
  max-height: 90vh;
}
.media-video {
  width: auto;
  max-height: 90vh;
  align-content: center;
  vertical-align: middle;
}
</style>