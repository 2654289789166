<template>
  <v-switch
    inset
    v-model="localContractEnabled"
    :true-icon="'mdi-brightness-3'"
    :false-icon="'mdi-brightness-5'"
    :class="{
      'custom-switch-on': !localContractEnabled,
      'custom-switch-off': localContractEnabled,
    }"
  >
    <template v-slot:label v-if="showLabel">
      Contrast Mode {{ !localContractEnabled ? "Low" : "High" }}
    </template>
  </v-switch>
</template>

<script>
import { useTheme } from "vuetify";
import { mapState, mapActions } from "vuex";

export default {
  props: {
    showLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      localContractEnabled: false,
      theme: useTheme(),
    };
  },
  watch: {
    localContractEnabled() {
      this.updateContrast(this.localContractEnabled);
      this.theme.global.name = this.localContractEnabled
        ? "dark"
        : "light";
    },
  },
  created() {
    this.localContractEnabled = this.highContrastEnabled;
    this.$vuetify.theme.themes.light =
    this.$vuetify.theme.themes["myCustomLightTheme"];
    this.$vuetify.theme.themes.dark =
    this.$vuetify.theme.themes["myCustomDarkTheme"];
    this.theme.global.name = this.localContractEnabled
      ? "dark"
      : "light";
  },
  computed: {
    ...mapState(["highContrastEnabled"]),
  },
  methods: {
    ...mapActions(["updateContrast"]),
    updateContrastSetting() {
      
    }
  },
};
</script>

<style>
.custom-switch-on,
.custom-switch-off {
  --v-switch-track-height: 24px;
  --v-switch-thumb-size: 24px;
  --v-switch-track-width: 48px;
  transform: scale(1.5);
  margin-left: 100px;
  margin-right: 20px;
}

.custom-switch-on .v-label,
.custom-switch-off .v-label {
  font-size: 12px;
}
.custom-switch-on .mdi-volume-high,
.custom-switch-on .mdi-brightness-5 {
  color: white;
  font-size: 21px;
  color: red;
}
.custom-switch-off .mdi-volume-off,
.custom-switch-off .mdi-brightness-3 {
  color: white;
  font-size: 14px;
  background: black;
}
.custom-switch-off .v-switch__thumb {
  background: black;
}
</style>