<template>
  <v-app-bar :class="appBarClass">
    <img class="app-image" src="@/assets/logo.svg" alt="Transport for west midlands logo" />
    <v-toolbar-title class="app-title">      
      <div class="titleText1">{{ this.titleText1 }}</div>
      <div class="titleText2">{{ this.titleText2 }}</div>
    </v-toolbar-title>
    <time-display class="time-display" />
  </v-app-bar>
</template>

<script>
import TimeDisplay from "./TimeDisplay.vue";
export default {
  components: {
    TimeDisplay,
  },
  props: {
    stopDescription: {
      type: Object,
      required: true,
    },
  },
  methods: {
    capitalizeEveryWord(sentence) {
      return sentence == null
        ? ""
        : sentence.split(' ')  
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
                  .join(' ');
    },
  },
  computed: {
    appBarClass() {
      if (process.env.NODE_ENV == 'development') return "app-bar-header-debug";
      return "app-bar-header";
    },
    titleText1() {
      return  `${this.stopDescription.indicator} ${this.capitalizeEveryWord(this.stopDescription.commonName)}`
    },
    titleText2() {
      return  this.capitalizeEveryWord(this.stopDescription.street)
    },
  },
};
</script>

<style scoped>
.titleText1, .titleText2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.app-title {
  color: white;
  font-size: 20px;
   margin-left:10px !important; 
}
.app-image {
  height: 40px;
  width: auto;
  margin-left: 10px;
}

.time-display {
  margin-right: 5px;
  color: white !important;
}
.app-bar-header {
  background-color: rgb(var(--v-theme-customer-main)) !important;
  color: rgb(var(--v-theme-customer-main)) !important;
} 
.app-bar-header-debug {
  color: rgb(var(--v-theme-customer-main)) !important;
}
</style>