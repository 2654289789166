<template>
  <div>
    <v-app-bar class="menu-app-bar" absolute>
      <v-btn class="menu-btn ml-0 pt-0" @click="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>Menu
      </v-btn>
      <v-spacer></v-spacer>
      <RefreshButtonVue v-if="showRefreshButton" class="refresh-btn" />
    </v-app-bar>
    <v-navigation-drawer class="nav-drawer" v-model="drawer" width="350">
      <v-container class="link-container">
        <v-row>
          <v-col cols="10"></v-col>
          <v-col cols="1">
            <v-btn v-if="drawer" variant="plain" @click="drawer = !drawer">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col></v-row
        >
        <v-row class="">
          <v-col v-if="splashEnabled" cols="12" class="v-col">
            <v-btn
              variant="plain"
              class="home-btn text-nav-btn-text ma-2 mb-5"
              @click="showSplashScreen()"
            >
              <v-icon>mdi-home</v-icon>&nbsp;&nbsp;Home
            </v-btn>
          </v-col>
          <v-col cols="12" class="v-col">
            <v-btn
              variant="plain"
              class="home-btn text-nav-btn-text ma-2 mb-5"
              @click="showTimetable()"
            >
              <v-icon>mdi-timetable</v-icon>&nbsp;&nbsp;Departures
            </v-btn></v-col
          >
          <v-col cols="12" class="v-col">
            <v-btn
              variant="plain"
              class="home-btn text-nav-btn-text ma-2 mb-5"
              @click="showMessages()"
            >
              <v-icon>mdi-message</v-icon>&nbsp;&nbsp;Information Messages
            </v-btn></v-col
          >
          <v-col cols="12" class="v-col">
            <v-btn
              variant="plain"
              class="home-btn text-nav-btn-text ma-2 mb-5"
              @click="showLinks()"
            >
              <v-icon>mdi-web</v-icon>&nbsp;&nbsp;Links
            </v-btn></v-col
          >
          <v-col cols="12" class="v-col">
            <v-btn
              variant="plain"
              class="home-btn text-nav-btn-text ma-2 mb-5"
              @click="showCookieSettings()"
            >
              <v-icon>mdi-cookie</v-icon>&nbsp;&nbsp;Cookies
            </v-btn></v-col
          >
        </v-row>
      </v-container>

      <template v-slot:append>
        <div class="margin-bottom">
          <v-card class="nav-draw-footer mb-5" variant="text">
            <v-card-title
              ><v-icon left>mdi-wheelchair</v-icon>&nbsp; Accessibility
              Settings</v-card-title
            >
            <contrast-switch />
            <speech-switch />
          </v-card>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import ContrastSwitch from "./ContrastSwitch.vue";
import SpeechSwitch from "./SpeechSwitch.vue";
import RefreshButtonVue from "./RefreshButton.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    ContrastSwitch,
    RefreshButtonVue,
    SpeechSwitch,
  },
  data: () => ({
    audioSwitchValue: true,
    drawer: false,
  }),
  methods: {
    ...mapActions(["updateCurrentPage"]),
    showCookieSettings() {
      this.$emit("showCookieSettings");
    },
    showSplashScreen() {
      this.updateCurrentPage("splash-screen");
      this.drawer = false;
    },
    showMessages() {
      this.updateCurrentPage("messages");
      this.drawer = false;
    },
    showTimetable() {
      this.updateCurrentPage("timetable");
      this.drawer = false;
    },
    showLinks() {
      this.updateCurrentPage("links");
      this.drawer = false;
    },
  },
  computed: {
    ...mapState(["currentPage", "splashEnabled"]),
    showRefreshButton() {
      return this.currentPage !== "messages" && this.currentPage !== "links";
    },
  },
};
</script>

<style>
.refresh-btn {
  padding: 0;
  margin-right: 5px;
}
.margin-bottom {
  margin-bottom: 14vh;
}
.link-container .v-col {
  padding: 0px;
  margin: 0px;
}
.menu-btn {
  z-index: 1009 !important;
  color: rgb(var(--v-theme-surface-variant-2)) !important;
  background-color: rgb(var(--v-theme-on-surface-variant)) !important;
}
.nav-drawer {
  top: 0vh !important;
  height: 65vh !important;
  max-width: 400px;
  margin: 0 auto;
}
.home-btn {
  margin-left: 0px !important;
  margin-right: 100px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  font-weight: bolder;
  font-size: 16px !important;
  float: left;
}
.menu-app-bar .v-toolbar__content {
  height: 6vh !important;
}
.menu-app-bar {
  z-index: 1008 !important ;
  position: fixed !important;
  top: 0vh !important;
  background: rgb(var(--v-theme-on-surface-variant)) !important;
  height: 6vh;
}

@media (orientation: landscape) {
  .menu-app-bar {
    height: 10vh !important;
  }
  .menu-app-bar .v-toolbar__content {
    height: 44px !important;
  }
}
.nav-draw-footer {
  padding-top: 16px !important;
  background: secondary !important;
}
.custom-switch-on,
.custom-switch-off {
  --v-switch-track-height: 24px;
  --v-switch-thumb-size: 24px;
  --v-switch-track-width: 48px;
  transform: scale(1.5);
  margin-left: 100px;
  margin-right: 20px;
}
.custom-switch-on .mdi-volume-high,
.custom-switch-on .mdi-brightness-5 {
  color: white;
  font-size: 21px;
  color: red;
}
.custom-switch-off .mdi-volume-off,
.custom-switch-off .mdi-brightness-3 {
  color: white;
  font-size: 14px;
  background: black;
}
.custom-switch-off .v-switch__thumb {
  background: black;
}
</style>