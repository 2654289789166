import { createApp } from "vue";
import App from "./App.vue";
import { createRouter, createWebHistory } from 'vue-router'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css';
import store from '../store/store';

//router
const routes = [
  { path: '/', component: App },
  { path: '/:id', component: App },
  {
    path: '/cookies.html',
    beforeEnter() {
      window.location.href = '/cookies.html';
    }
  }
]

const router = createRouter({
history: createWebHistory(),
routes,
})


// Vuetify
const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',//white-ish
    'background-dark': '#8e8e8e',
    surface: '#FFFFFF',//white-ish
    'surface-bright': '#FFFFFF',//white-ish
    'surface-light': '#EEEEEE',
    'surface-variant': '#424242',
    'surface-variant-2': '#2b2b2b',
    'surface-variant-3': '#424242',
    'on-surface-variant': '#EEEEEE',
    primary: '#1867C0',
    'primary-darken-1': '#1F5592',
    secondary: '#48A9A6',
    'secondary-darken-1': '#018786',
    error: '#f00707',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#e4fc08',
    message: '#fcd308',
    'popup-primary': '#000000',
    'popup-secondary': '#fcd308',
    'nav-btn-text': '#000000',
    'my-color': '#fcd308',
    'customer-main': '#ea0029',
    divider: '#000000',
    'splash-footer': '#212121'

  },
  variables: {
    'border-color': '#000000',
    'border-opacity': 0.12,
    'high-emphasis-opacity': 0.87,
    'medium-emphasis-opacity': 0.60,
    'disabled-opacity': 0.38,
    'idle-opacity': 0.04,
    'hover-opacity': 0.04,
    'focus-opacity': 0.12,
    'selected-opacity': 0.08,
    'activated-opacity': 0.12,
    'pressed-opacity': 0.12,
    'dragged-opacity': 0.08,
    'theme-kbd': '#212529',
    'theme-on-kbd': '#FFFFFF',
    'theme-code': '#F5F5F5',
    'theme-on-code': '#000000',
  }
}

// Vuetify
const myCustomDarkTheme = {
  dark: true,
  colors: {
    background: '#121212',
    'background-dark': '#000000',
    surface: '#1E1E1E',
    'surface-bright': '#333333',
    'surface-light': '#2C2C2C',
    'surface-variant': '#FFFFFF',//white-ish
    'surface-variant-2': '#1c1c1c',
    'surface-variant-3': '#000000',
    'on-surface-variant': '#FFFFFF',//white-ish
    primary: '#2196F3',
    'primary-darken-1': '#1976D2',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#CF6679',
    info: '#BB86FC',
    success: '#4CAF50',
    warning: '#FFB300',
    message: '#FFEA00',
    'popup-primary': '#212121',
    'popup-secondary': '#FFEA00',
    'nav-btn-text': '#FFFFFF',//white-ish
    'my-color': '#FFEA00',
    'customer-main': '#000000',
    divider:'#FFFFFF',
    'splash-footer': '#e0e0e0'
  },
  variables: {
    'border-color': '#FFFFFF',
    'border-opacity': 0.12,
    'high-emphasis-opacity': 0.87,
    'medium-emphasis-opacity': 0.60,
    'disabled-opacity': 0.38,
    'idle-opacity': 0.04,
    'hover-opacity': 0.04,
    'focus-opacity': 0.12,
    'selected-opacity': 0.08,
    'activated-opacity': 0.12,
    'pressed-opacity': 0.12,
    'dragged-opacity': 0.08,
    'theme-kbd': '#2C2C2C',
    'theme-on-kbd': '#FFFFFF',
    'theme-code': '#333333',
    'theme-on-code': '#FFFFFF',
  }
};



const vuetify = createVuetify({
  components,
  directives,
  icons: {
      iconfont: 'mdi',
},
theme: {
  defaultTheme: 'myCustomLightTheme',
  options: {
    customProperties: true, 
  },
  themes: {
    myCustomLightTheme,
    myCustomDarkTheme,
  },
},
})

const app = createApp(App);

app.use(store);
app.use(router);
app.use(vuetify);
app.mount('#app');
