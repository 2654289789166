<template>
  <div v-if="getmessagesForQRCode.length > 0" class="bg-surface ma-0">
    <v-container
      fluid
      class="pl-0 pr-0 information-popup-header-container ma-0 pa-0"
    >
      <v-row
        class="information-popup-header ma-0"
        :class="minimisePopUp ? 'bg-popup-secondary' : 'bg-popup-primary'"
      >
        <v-col cols="10">
          <h2 :aria-label="messageTitle">
            <v-icon
              class="information-popup-icon"
              :class="minimisePopUp ? 'bg-popup-primary' : ''"
              size="small"
              >mdi-exclamation</v-icon
            >&nbsp; Information Message
          </h2>
        </v-col>
        <v-col cols="2" class="pl-0 pr-3">
          <v-btn
            :icon="minimisePopUp ? 'mdi-chevron-up' : 'mdi-close'"
            size="v-small"
            :disabled="disableButton"
            :aria-label="
              minimisePopUp ? 'open message button' : 'close message button'
            "
            v-if="!hideButton"
            @click="handleMinimiseToggleClick"
            :class="
              minimisePopUp
                ? 'minimise-button-minimised'
                : 'minimise-button-maximised'
            "
          ></v-btn>
        </v-col> </v-row
    ></v-container>

    <div :hidden="minimisePopUp" :class="getClass()">
      <stop-messages
        v-if="!minimisePopUp"
        :messages="getmessagesForQRCode"
        :latestMessageOnly="latestMessageOnly"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import StopMessages from "./StopMessages.vue";
import { messagesToSpeak } from "../utils/textToSpeechUtils";
import { MessageType } from "../../constants";

export default {
  components: {
    StopMessages,
  },
  props: {
    latestMessageOnly: {
      type: Boolean,
      required: false,
      default: false,
    },

    isSafari: {
      type: Boolean,
      required: false,
      default: false,
    },
    messageType: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      messageHeading: "Information",
      minimisePopUp: false,
      fullScreen: false,

      hideTimeOut: 0,
      myHideTimer: null,

      disabledOutTimer: 0,
      myDisableTimer: null,

      disableButton: false,
      hideButton: false,
    };
  },
  methods: {
    ...mapActions(["updateAudio", "updatePopupMinimised"]),
    ...mapMutations(["setDisableMenu"]),
    getClass() {
      let classList = "";
      if (this.isSafari) {
        classList = this.fullScreen
          ? "message-content-max-ios"
          : "message-content-min";
      } else {
        classList = this.fullScreen
          ? "message-content-max"
          : "message-content-min";
      }
      return classList;
    },
    handleMinimiseToggleClick() {
      this.minimisePopUp = !this.minimisePopUp;
      clearInterval(this.myHideTimer);
      this.updatePopupMinimised(this.minimisePopUp);
    },
    startHideTimer() {
      this.myHideTimer = setInterval(() => {
        this.hideTimeOut--;
        if (this.hideTimeOut <= 0) {
          this.minimisePopUp = true;
          this.updatePopupMinimised(this.minimisePopUp);
          clearInterval(this.myHideTimer);
        }
      }, 1000);
    },
    startDisableTimer() {
      this.myDisableTimer = setInterval(() => {
        this.disabledOutTimer--;
        if (this.disabledOutTimer <= 0) {
          this.disableButton = false;
          clearInterval(this.myDisableTimer);
        }
      }, 1000);
    },
    nonDismissableMessageAction() {
      this.minimisePopUp = false;
      this.disableButton = true;
      this.hideButton = true;
      this.setDisableMenu(true);
      this.updateAudio(true);
    },
    fullScreenMessageAction() {
      if (this.popupMinimised) {
        this.minimisePopUp = true;
        this.disableButton = false;
      } else {
        this.minimisePopUp = false;
        this.disableButton = true;

        this.startHideTimer();
        this.startDisableTimer();
      }

      this.hideButton = false;
      this.fullScreen = true;
    },
    timeoutMessageAction() {
      if (this.popupMinimised) {
        this.minimisePopUp = true;
        this.disableButton = false;
      } else {
        this.minimisePopUp = false;
        this.disableButton = true;

        this.startHideTimer();
        this.startDisableTimer();
      }

      this.hideButton = false;
      this.fullScreen = false;
    },
    initiallyMinimiseMessageAction() {
      this.minimisePopUp = true;
      this.disableButton = false;
      this.hideButton = false;
      this.fullScreen = false;
    },
    setupUIBasedOnMessages() {
      if (this.messageType == MessageType.NON_DISMISSABLE) {
        this.nonDismissableMessageAction();
      } else if (this.messageType == MessageType.FULL_SCREEN) {
        this.fullScreenMessageAction();
      } else if (this.messageType == MessageType.TIME_OUT && !this.popupPreviouslyMinimised) {
        this.timeoutMessageAction();
      } else if (this.messageType == MessageType.INITIALLY_MINIMISED || this.popupPreviouslyMinimised) {
        this.initiallyMinimiseMessageAction();
      }
    },
  },
  mounted() {
    this.hideTimeOut = parseInt(
        process.env.VUE_APP_MESSAGE_POPUP_AUTO_CLOSE_TIMER,
        10
    ),
    this.disabledOutTimer = parseInt(
        process.env.VUE_APP_MESSAGE_POPUP_DISABLE_BUTTON_TIMER,
        10
      )
    this.setupUIBasedOnMessages();
  },
  computed: {
    ...mapGetters(["getmessagesForQRCode"]),
    ...mapState(["popupMinimised", "popupPreviouslyMinimised"]),
    messageTitle() {
      let title = "Information Message! ";
      if (this.getmessagesForQRCode.length > 0) {
        title =
          title +
          messagesToSpeak(this.getmessagesForQRCode, this.latestMessageOnly);
      }
      return title;
    },
  },
  watch: {
    getmessagesForQRCode() {
      this.setupUIBasedOnMessages();
    },
  },
};
</script>

<style>
.message-card {
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;

  font-size: 16px !important;

  text-align: left;
  transition: all 0.3s;
  background: rgb(var(--v-theme-error));
}

.message-card span {
  font-size: 20px !important;
}

.minimise-button-minimised {
  border: 2px solid !important;
  border-color: rgb(var(--v-theme-surface-variant-2)) !important;
  color: rgb(var(--v-theme-surface-variant-2)) !important;
  background-color: transparent !important;
}

.minimise-button-maximised {
  border: 2px solid !important;
  color: var(--v-my-color) !important;
  border-color: white !important;
  background-color: transparent !important;
}

.information-popup-header-container {
  left: 0;
  top: 0vh;
}

.information-popup-header {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  width: 100%;
  max-width: 100% !important;
  -moz-border-radius: 0px;
  -webkit-border-radius: 5px 5px 0px 0px;
  border-radius: 5px 5px 0px 0px;
}
.information-popup-icon {
  margin-bottom: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px; 
  height: 30px;
  border-radius: 50%;
  color: black;
  background: white;
}
</style>